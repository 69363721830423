

































































































import { defineComponent, ref } from '@vue/composition-api';
import { Scrollbar } from '@/app/components';

export default defineComponent({
    name: 'TermsAndConditions',
    components: { Scrollbar },
    model: {
        prop: 'termsAndConditions',
        event: 'update-terms-and-conditions',
    },
    props: {
        termsAndConditions: {
            type: Boolean,
            required: true,
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const showTermsAndConditions = ref<boolean>(false);

        const accepted = ref<boolean>(props.termsAndConditions);

        const change = () => {
            emit('update-terms-and-conditions', accepted.value);
        };

        return { accepted, showTermsAndConditions, change };
    },
});
